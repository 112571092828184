import { ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone, OnDestroy, Output, Renderer2, ViewChild, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CameraPreview, CameraPreviewOptions, CameraPreviewPictureOptions } from '@capacitor-community/camera-preview';
import { CameraService } from '../camera.service';
import { ModalController } from '@ionic/angular';
import { WarningModalComponent } from '../warning-modal/warning-modal.component';


export interface CameraOutputData {
  imageB64DataUrl: string;
}

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CameraComponent),
      multi: true
    }
  ]
})
export class CameraComponent implements ControlValueAccessor, OnDestroy {
  @Output() valueChange = new EventEmitter<CameraOutputData | null>();
  @Input() odometer = false;
  @Input() dashboard = false;
  onChange: any = () => { };
  onTouched: any = () => { };
  isDisabled: boolean = false;
  capturedImage$ = this.cameraService.capturedImage$;
  odometerImage: CameraOutputData | null = null;
  dashboardImage: CameraOutputData | null = null;

  // width: number | null = null;
  // height: number | null = null;
  // rectangle: {
  //     left: number,
  //     top: number,
  //     width: number,
  //     height: number
  // } | null = null;

  // @ViewChild('mainContainer') mainRef: ElementRef | null = null;
  // @ViewChild('cameraPreviewContainer') cameraPreviewRef: ElementRef | null = null;

  cameraStream: MediaStream | null = null;
  value: CameraOutputData | null = null;
  @ViewChild('cameraPreviewVideo') cameraPreviewVideoRef: ElementRef | null = null;


  constructor(private ngZone: NgZone, private renderer: Renderer2, private cdRef: ChangeDetectorRef, private cameraService: CameraService, private modalController: ModalController) { }

  writeValue(obj: null | CameraOutputData): void {
    if (obj !== null) {
      throw new Error('write value not implemented: ' + obj);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  async ngOnInit() {
    this.capturedImage$.subscribe((image) => {
      this.dashboardImage = image.dashboard;
      this.odometerImage = image.odometer
    });
    this.presentInstructionsModal();
    console.log('odometer', this.odometer, 'dashboard', this.dashboard)
  }

  async ngOnDestroy() {
    if (this.cameraStream) {
      await this.stop();
    }
  };

  ngAfterViewInit() {
    // if (!this.mainRef) {
    //     throw new Error("mainRef is null");
    // }
    // if (!this.cameraPreviewRef) {
    //     throw new Error("cameraPreviewRef is null");
    // }
    // const width = this.cameraPreviewRef.nativeElement.offsetWidth;
    // const height = this.cameraPreviewRef.nativeElement.offsetHeight;
    // this.cdRef.detectChanges();
    // setTimeout(() => {
    //     setTimeout(() => {
    //         const width = this.renderer.selectRootElement(this.mainRef!.nativeElement, true).offsetWidth;
    //         const height = width * ((4 / 3));
    //             // console.log(this.mainRef!.nativeElement.offsetWidth)
    //         this.width = width;
    //         this.height = height;
    //         this.rectangle = {
    //             left: 0,
    //             top: height * 0.5 - 100,
    //             width: width,
    //             height: 200
    //         };
    //         this.start();
    //     });
    // });
    // console.info(this.mainRef.nativeElement, width,height)

    this.start();
  }

  async start() {
    const cameraService = this.cameraService;
    const videoElement = this.cameraPreviewVideoRef?.nativeElement;

    if (!videoElement) {
      throw new Error("videoElement is null");
    }

    // if (this.width === null || this.height === null) {
    //     throw new Error("width/height is null");
    // }

    // Retrieve the list of available camera devices (& log, will be helpful
    // for testing & selecting because this is highly device specific).
    const cameraDevices = await cameraService.listCameraDevices();
    console.info('cameraDevices', cameraDevices);

    // Start the camera stream on our video element..
    const cameraStream = await cameraService.startStream(videoElement, cameraDevices);

    // Store the camera stream to be able to stop it later on.
    this.cameraStream = cameraStream;
  }

  async stop() {
    const cameraService = this.cameraService;
    const cameraStream = this.cameraStream;
    const videoElement = this.cameraPreviewVideoRef?.nativeElement;

    if (!videoElement) {
      throw new Error("videoElement is null");
    }

    // Stop the camera stream.
    if (cameraStream) {
      await cameraService.stopStream(videoElement, cameraStream);
      this.cameraStream = null;
    }
  }

  async capture() {
    const videoElement = this.cameraPreviewVideoRef?.nativeElement;
    const quality = 1.0;

    if (!videoElement) {
      throw new Error("videoElement is null");
    }

    // if (this.width === null || this.height === null) {
    //     throw new Error("width/height is null");
    // }

    // Generate image out of the video stream.
    // ...ensure the video dimensions are known
    const imageB64DataUrl = await new Promise<string>((resolve, reject) => {
      const processVideo = () => {
        try {
          // Create a new canvas element dynamically.
          const canvas = document.createElement('canvas');

          // Prepare scaling video capture of canvas to display's pixel ratio
          // for high resolution displays.
          const pixelRatio = window.devicePixelRatio || 1;
          canvas.width = videoElement.videoWidth * pixelRatio;
          canvas.height = videoElement.videoHeight * pixelRatio;

          // Draw the video frame to the canvas.
          const context = canvas.getContext('2d');
          if (!context) {
            throw new Error("context is null");
          }
          context.scale(pixelRatio, pixelRatio) // first scale the context to compensate for the increased canvas size.
          context.drawImage(videoElement, 0, 0, videoElement.videoWidth, videoElement.videoHeight);

          // Retrieve image data from the canvas.
          const imageB64DataUrl = canvas.toDataURL('image/jpeg', quality);

          // Resolve the promise with the base64 picture.
          resolve(imageB64DataUrl);
        }
        catch (error) {
          console.error(error);
          reject(error);
        }
      };

      if (videoElement.readyState >= 1) {
        processVideo();
      }
      else {
        videoElement.addEventListener('loadeddata', () => {
          processVideo();
        });
      }
    });

    // Stop the video stream. (just try out of safety for the rest to happen).
    try {
      await this.stop();
    }
    catch (e) {
      console.error(e);
    }

    // Set the value, used to display as preview.
    this.value = { imageB64DataUrl };

    // Emit the captured image data.
    this.valueChange.emit({ imageB64DataUrl });
    this.onChange({ imageB64DataUrl });

    // Make element dirty.
    this.onTouched();
    if (this.odometer) {
      this.cameraService.setCapturedImage('odometer', { imageB64DataUrl });
    } else if (this.dashboard) {
      this.cameraService.setCapturedImage('dashboard', { imageB64DataUrl });
    }

  }

  async reset() {
    this.value = null;
    this.valueChange.emit(null);
    if (this.odometer) {
      this.cameraService.setCapturedImage('odometer', null);
    } else if (this.dashboard) {
      this.cameraService.setCapturedImage('dashboard', null);
    }
    this.onChange(null);

    // Run outside Angular to wait for the rendering to finish.
    this.ngZone.runOutsideAngular(async () => {
      // Use requestAnimationFrame or setTimeout to wait for the next rendering cycle.
      requestAnimationFrame(() => {
        // Re-enter Angular zone to ensure change detection works as expected.
        this.ngZone.run(async () => {
          await this.start();
        });
      });
    });
  }


  async presentInstructionsModal() {
    const modal = await this.modalController.create({
      component: WarningModalComponent,
      componentProps: {
        odometer: this.odometer,
        dashboard: this.dashboard
      }
    });

    await modal.present();
  }
}
